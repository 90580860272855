import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import FDK from "@wattsonelements/front-fdk";
import { ITicketCategory } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
// import { fakeApiCall } from "../../../utils/FakeApi";
// import { MOCK_cateogries } from "../../../_faker/categories.mock";
import { RootState } from "../store";
import { UUID } from "@wattsonelements/front-fdk/dist/models/type.models";

export interface CategorieState {
  levels: Array<ITicketCategory[]>;
  selectedCategory: string | null;
  loading: boolean;
  error: any;
}

const initialState = {
  levels: [],
  selectedCategory: null,
  loading: false,
  error: null,
} as CategorieState;

export const getCategories = createAsyncThunk(
  "categories/getCategories",
  async (id: UUID | undefined, { getState }) => {
    const { categories } = getState() as RootState;
    return FDK.Agent.TicketsModule.getTicketsCategories(id).then((res) => {
      // if (res.data && res.data.length > 0 && !selected) {
      //   dispatch(slice.actions.setSelectedCategory(res.data[0].id));
      // }
      return res.data;
    });
    // return fakeApiCall(MOCK_cateogries).then((res) => {
    //   if (res && res.length > 0)
    //     dispatch(slice.actions.setSelectedCategory(res[0].id));
    //   return res;
    // });
  }
);

export const slice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setSelectedCategory: (state, { payload }) => {
      state.selectedCategory = payload;
    },
    resetCategories: (state) => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCategories.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.levels = [];
        state.levels.push(
          payload.filter((cat: ITicketCategory) => cat.parent_category === null)
        );
        state.levels.push(
          payload.filter((cat: ITicketCategory) => cat.parent_category !== null)
        );
      })
      .addCase(getCategories.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export default slice.reducer;

export const { setSelectedCategory, resetCategories } = slice.actions;
