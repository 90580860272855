import { useHistory, useLocation } from "react-router-dom";
import jwt_decode, { JwtPayload } from "jwt-decode";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { logout as logoutAction, login } from "../store/reducers/Auth";
import { usePeriodic } from "./Periodic.service";
import { getCategories } from "../store/reducers/Categories";
import { getAgents } from "../store/reducers/Users";
import { getUserPort } from "../store/reducers/Ports";
import { saveItem } from "../../utils/asyncStorage";
import FDK from "@wattsonelements/front-fdk";
import { useQAContext } from "../context/QAContext";
import { addListeners, registerForPushNotificationsAsync, registerNotifications } from "../notifications";
import { addListener } from "process";
import { IPortAgent } from "@wattsonelements/front-fdk/dist/models/Port.models";
// import {
//   getAgentsManagersList,
//   getUserProfile,
// } from "../store/actions/User.actions";
// import { getUserPort } from "../store/actions/Port.actions";
// import {
//   getTicketsCategories,
//   getTicketsList,
// } from "../store/actions/Ticket.actions";
// import FDK from "@wattsonelements/front-fdk";

export const useAuth = () => {
  // let navigate = useNavigate();
  const history = useHistory();
  let dispatch = useAppDispatch();
  let location = useLocation();
  const { API_URL } = useQAContext();
  const { clearAll } = usePeriodic();

  const { isLoggedIn } = useAppSelector((state) => state.auth);
  const logout = () => {
    if (isLoggedIn === false) { loggedOutNavigate(); return };
    localStorage.removeItem("token");
    localStorage.removeItem("refresh");
    dispatch(logoutAction());
    loggedOutNavigate();
    clearAll();
  };

  const loggedOutNavigate = () => {
    if (
      !(
        location.pathname.includes("connexion") ||
        location.pathname.includes("reset-password") ||
        location.pathname.includes("forget-password")
      )
    ) {
      history.push("/login")
    }
  };
  const redirection = (isDefault = false) => {
    if (!isLoggedIn) {
      logout();
    } else if (isLoggedIn && isDefault) {
      history.push("/dashboard/opens")
    }
  };

  const checkLogin = () => {

    const refresh = localStorage.getItem("refresh");
    const access = localStorage.getItem("token");
    let decoded = refresh && jwt_decode<JwtPayload>(refresh || "");
    if (
      refresh &&
      access &&
      decoded &&
      typeof decoded?.exp === "number" &&
      decoded?.exp! > Math.floor(Date.now() / 1000)
    ) {
      dispatch(login());

      return true;
    } else {
      logout();
      return false;
    }
  };

  const init = async () => {
    console.log("init", isLoggedIn);

    if (isLoggedIn === "pending") return;
    if (!isLoggedIn) {
      logout();
    } else {
      console.log("init login", isLoggedIn);
      return FDK.Auth.refreshToken(localStorage.getItem("refresh") || '', API_URL).then(
        ({ data }) => {
          saveItem("token", data.access);
          saveItem("refresh", data.refresh);
          dispatch(login())
          dispatch(getUserPort()).then(res => {
            dispatch(getCategories((res.payload as IPortAgent).id))
          })
          dispatch(getAgents());
          registerNotifications()
          addListeners()
        },
        (err) => {
          logout();
        }
      );
    }
  };

  return { logout, redirection, checkLogin, init };
};
