import React, { useEffect, useState } from "react";
import DashboardHeader from "../components/layouts/DashboardHeader";
import TicketsList from "../components/ticketsList/ticketsList";

import { useAppSelector, useAppDispatch } from "../core/store/hooks";
import {
  getFilteredTicketList,
  isCurrentListLoading,
} from "../core/store/selectors/tickets";
import InterventionMenu from "../components/layouts/InterventionMenu/InterventionMenu";
import { filterTicketsByPriority } from "../core/store/selectors/helpers";
import {
  getTickets,
  setCurrentList,
  setSelectedTicket,
  updateFilter,
} from "../core/store/reducers/Tickets";
import { usePeriodic } from "../core/services/Periodic.service";
import { IonContent, IonHeader, IonPage, IonRefresher, IonRefresherContent, IonSpinner, RefresherEventDetail } from "@ionic/react";
import { createUseStyles } from "react-jss";
import { colors } from "../styles/colors";
import { useLocation, useParams } from "react-router";
import { getCategories } from "../core/store/reducers/Categories";

import { getAgents } from "../core/store/reducers/Users";
import { useQAContext } from "../core/context/QAContext";
import TicketFilterMenu from "../components/layouts/InterventionMenu/TicketFilterMenu";
import { ITicket } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
import { TicketPriority } from "@wattsonelements/front-fdk/dist/constants/ticket.constants";
import FDK from "@wattsonelements/front-fdk";
import { getUserPort } from "../core/store/reducers/Ports";
import { skipToken } from "@reduxjs/toolkit/query";
import { useGetConfigQuery } from "../core/store/api/ticket.api";
import { IPortAgent } from "@wattsonelements/front-fdk/dist/models/Port.models";

export default function DashboardPage() {

  const route = useLocation();
  const [filteredTickets, setFilteredTickets] = useState<any>(undefined);
  const { APP_VERSION } = useQAContext();

  const dispatch = useAppDispatch();

  const tickets = useAppSelector(getFilteredTicketList);
  const isListLoading = useAppSelector(isCurrentListLoading);
  const isTicketsLoading = useAppSelector((state) => state.tickets.loading);
  const currentList = useAppSelector((state) => state.tickets.currentList);
  const port = useAppSelector((state) => state.ports.current);
  const { startPeriodic } = usePeriodic();
  const periodicName = "get-ticket-list";
  const classNames = useclassNames();
  const { ticketsType } = useParams<any>();
  useGetConfigQuery(port?.id ?? skipToken)

  useEffect(() => {
    // if (!ticketLoading) {
    setFilteredTickets(
      filterTicketsByPriority(tickets) as { [key in TicketPriority]?: ITicket[] }
    );
    // }
  }, [tickets]);


  useEffect(() => {
    dispatch(setSelectedTicket(null));
    dispatch(getAgents());
    dispatch(getUserPort()).then(res => {
      dispatch(getCategories((res.payload as IPortAgent).id))
    })
    switch (route.pathname) {
      case "/":
      case "/dashboard":
      case "/dashboard/opens":
        dispatch(setCurrentList("listOpens"));
        // stopPeriodic(`${periodicName}-closed`);
        startPeriodic(`${periodicName}`, 10, () => dispatch(getTickets()));
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);


  const onRefresh = React.useCallback((event: CustomEvent<RefresherEventDetail>) => {
    dispatch(getUserPort()).then(res => {
      dispatch(getCategories((res.payload as IPortAgent).id))
    })
    dispatch(getAgents());
    try {
      dispatch(getTickets()).then(() => {
        event.detail.complete()
      });
    } catch (error) {
      event.detail.complete()
    }
  }, []);

  const search = (e: any) => {
    dispatch(updateFilter({ text: e.detail.value }))
  }


  return (
    <IonPage key="dashboard-page">
      <IonHeader mode="md">
        <div className={classNames.container}>
          <DashboardHeader search={search} />
          <TicketFilterMenu />
          <InterventionMenu />
        </div>

      </IonHeader>
      <IonContent key="dashboard-content">
        <div className="pt-1 px-4 h-full bg-LIGHT">

          <IonRefresher slot="fixed" onIonRefresh={onRefresh}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>

          {isListLoading && (!tickets || tickets.length === 0) && (
            <div className="mt-5 flex justify-center items-center">
              <IonSpinner name="crescent" color="secondary" />
            </div>
          )}
          {filteredTickets && (
            <div className="pb-8"
              style={{
                transform: "translate3D(0,0,0)",
                opacity: currentList === 'listClosed' ? "0.5" : "1"
              }}>
              {filteredTickets?.urgent.length > 0 && (
                <TicketsList
                  key="t-urgent"
                  className={classNames.list}
                  tickets={filteredTickets?.urgent}
                />
              )}
              {filteredTickets?.intermediate.length > 0 && (
                <TicketsList
                  key="t-intermediate"
                  className={classNames.list}
                  tickets={filteredTickets?.intermediate}
                />
              )}
              {filteredTickets?.normal.length > 0 && (
                <TicketsList
                  key="t-normal"
                  className={classNames.list}
                  tickets={filteredTickets?.normal}
                />
              )}
              <p className={classNames.version}>
                {APP_VERSION}
                {port && port.name && <> - {port.name}</>}
              </p>
            </div>
            // </Scrolldiv>
          )}
        </div>
      </IonContent>

    </IonPage>
  );
}

const useclassNames = createUseStyles({
  container: {
    alignSelf: "stretch",
    backgroundColor: "#ececf3"
  },
  version: {
    textAlign: "center",
    color: colors.GREY,
    fontSize: 10,
    margin: "5px 0",
  },
  lists: {
    // problem at the bottom
    marginTop: 20,
    marginBottom: 120,
  },
  list: {
    marginBottom: 10,
  },
  modal: {
    backgroundColor: colors.CALCITE,
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
    borderRadius: 5,
    width: 100,
    height: 100,
    borderWidth: 1,
    borderColor: colors.LIGHT_GREY,
  },
  modalContainer: {
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 20,
  },
});
